import { Button, Stack } from "@mui/material";
import React from "react";
import LoadingButtonIcon from "../LoadingButtonIcon";
import { i18n } from "../../translate/i18n";

type Props = {
	isSubmitDisabled: boolean;
	isLoading: boolean;
	onCancelCallback: Function;
	handleDataReset: () => void;
	onSubmitCallback: Function;
};

export default function OrderCheckActions({
	isSubmitDisabled,
	isLoading,
	onCancelCallback,
	handleDataReset,
	onSubmitCallback,
}: Props) {
	return (
		<>
			<Button
				variant="contained"
				color="danger"
				onClick={() => onCancelCallback()}
				disabled={isLoading}
			>
				{i18n.t("orderCheck.RefuseOrder").toString()}
			</Button>
			<Stack direction="row" gap={2}>
				<Button
					variant="outlined"
					color="warning"
					onClick={() => handleDataReset()}
					disabled={isLoading}
				>
					{i18n.t("orderCheck.RedoConference").toString()}
				</Button>
				<Button
					variant="contained"
					color="success"
					onClick={() => onSubmitCallback()}
					disabled={isSubmitDisabled || isLoading}
				>
					{isLoading ? (
						<LoadingButtonIcon />
					) : (
						i18n.t("orderCheck.AcceptOrder").toString()
					)}
				</Button>
			</Stack>
		</>
	);
}
