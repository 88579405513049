import React, { Dispatch, ReactNode, SetStateAction } from "react";
import CustomDialog from "../MUICustomComponents/CustomDialog";
import { i18n } from "../translate/i18n";
import CustomIcon from "../MUICustomComponents/CustomIcon";
import { RemoveCircle } from "@mui/icons-material";
import CustomTypography from "../MUICustomComponents/CustomTypography";
import { Stack } from "@mui/material";

type Props = {
	children: ReactNode;
	isAttentionDialogOpen: boolean;
	setIsAttentionDialogOpen: Dispatch<SetStateAction<boolean>>;
	closeCallback?: Function;
};

export default function AttentionDialog({
	children,
	isAttentionDialogOpen,
	setIsAttentionDialogOpen,
	closeCallback,
}: Props) {
	return (
		<CustomDialog
			isOpen={isAttentionDialogOpen}
			setIsOpen={setIsAttentionDialogOpen}
			actionButton={{
				text: i18n.t("components.AttentionDialog.ConfirmBtn"),
				variant: "outlined",
				color: "blueish80",
				onClick: () => setIsAttentionDialogOpen(false),
			}}
			dialogStyles={{
				titleVariant: "title",
				actionStyles: { justifyContent: "center" },
				customMaxWidth: "382px",
			}}
			closeCallback={closeCallback}
			showCancelButton={false}
			showCloseButton={false}
		>
			<Stack direction="column" alignItems="center">
				<CustomIcon
					icon={<RemoveCircle />}
					variant="custom"
					color="danger"
					fontSize="60px"
				/>
				<CustomTypography
					variant="secondaryTitle"
					color="danger"
					marginBottom={2}
					sx={{ textAlign: "center" }}
				>
					{i18n.t("components.AttentionDialog.Attention").toString()}
				</CustomTypography>
				{children}
			</Stack>
		</CustomDialog>
	);
}
