import React, { Dispatch, SetStateAction } from "react";
import { i18n } from "../../translate/i18n";
import CustomDialog from "../../MUICustomComponents/CustomDialog";
import { OrderModel } from "../../domain/models/commerce/OrderModel";
import OrderCheckDialogService from "./OrderCheckDialogService";
import {
	Box,
	Divider,
	LinearProgress,
	linearProgressClasses,
	Stack,
} from "@mui/material";
import OrderCheckHeader from "./OrderCheckHeader";
import { paletteObject } from "../../theme/foundations/palette";
import CustomInput from "../../MUICustomComponents/CustomInput";
import EanIcon from "../../assets/payments-flags/ean-icon.svg";
import OrderCheckTable from "./OrderCheckTable";
import CustomTypography from "../../MUICustomComponents/CustomTypography";
import QuantityInputSelector from "../QuantityInputSelector";
import AttentionDialog from "../AttentionDialog";
import { insertHtml } from "../../infrastructure/utils/InsertHtml";
import OrderCheckActions from "./OrderCheckActions";
import EmptyComponent from "../EmptyComponent/EmptyComponent";
import CustomSkeleton from "../../MUICustomComponents/CustomSkeleton";

type Props = {
	orderModel: OrderModel;
	isOrderCheckDialogOpen: boolean;
	setIsOrderCheckDialogOpen: Dispatch<SetStateAction<boolean>>;
	isLoadingSubmit: boolean;
	onCancelCallback: Function;
	onSubmitCallback: Function;
};

export default function OrderCheckDialog({
	orderModel,
	isOrderCheckDialogOpen,
	setIsOrderCheckDialogOpen,
	isLoadingSubmit,
	onCancelCallback,
	onSubmitCallback,
}: Props) {
	const {
		orderTableItems,
		currentDealer,
		isDealerListEmpty,
		currentEanValue,
		checkedItemsQuantity,
		checkedItemsProgress,
		packagesQuantity,
		isAttentionDialogOpen,
		notFoundEan,
		isLoadingDealers,
		triggerLoadingDealers,
		setNotFoundEan,
		setIsAttentionDialogOpen,
		setPackagesQuantity,
		setCurrentDealer,
		emptyDealersCallback,
		handleAutocompleteChange,
		handleEanInput,
		submitEan,
		handleDataReset,
		onSubmit,
	} = OrderCheckDialogService(orderModel, onSubmitCallback);

	const renderContent = () => {
		if (isLoadingDealers) return <CustomSkeleton quantity={6} height={40} />;
		if (isDealerListEmpty)
			return (
				<EmptyComponent
					imageSrc={"/images/empty_dealer.svg"}
					title={i18n.t("orderCheck.EmptyDealerList")}
					message={i18n.t("orderCheck.EmptyDealerListMessage")}
					createButton={{
						text: i18n.t("orderCheck.NewDealerButton"),
						color: "grey80",
						variant: "contained",
						onClick: () => (window.location.href = "/salespeople/1/new"),
					}}
				/>
			);
		return (
			<>
				<Stack direction="row" gap={4} marginBottom={3}>
					<Box flex={1}>
						<CustomTypography variant="small" marginBottom={1} isBold>
							{i18n.t("orderCheck.EanLabel").toString()}
						</CustomTypography>
						<CustomInput
							title={i18n.t("orderCheck.Ean")}
							name="eanInput"
							value={currentEanValue}
							onChange={handleEanInput}
							onKeyDown={(event) => {
								if (event.key === "Enter") {
									submitEan();
								}
							}}
							endAdornment={<img src={EanIcon} />}
							autoFocus
						/>
					</Box>
					<Box flex={1.2}>
						<CustomTypography variant="small" marginBottom={1.5} isBold>
							{i18n.t("orderCheck.ProgressLabel").toString()}
						</CustomTypography>
						<LinearProgress
							variant="determinate"
							value={checkedItemsProgress}
							sx={{
								height: 10,
								marginBottom: 1,
								borderRadius: 8,
								[`&.${linearProgressClasses.colorPrimary}`]: {
									backgroundColor: paletteObject.grey20.main,
								},
								[`& .${linearProgressClasses.bar}`]: {
									backgroundColor: paletteObject.success.main,
									borderRadius: 8,
								},
							}}
						/>
						<CustomTypography
							variant="small"
							isBold
							sx={{ textAlign: "center" }}
						>
							{i18n
								.t("orderCheck.ItemsChecked")
								.replace("%X", String(checkedItemsQuantity))
								.replace("%Y", String(orderModel.itemQuantity))}
						</CustomTypography>
					</Box>
				</Stack>
				<OrderCheckTable orderTableItems={orderTableItems} />
				<Stack
					direction="row"
					justifyContent="end"
					alignItems="center"
					marginTop={2}
					gap={2}
				>
					<CustomTypography variant="default" isBold>
						{i18n.t("orderCheck.PackagesQuantity").toString()}
					</CustomTypography>
					<QuantityInputSelector
						value={packagesQuantity}
						setValue={setPackagesQuantity}
					/>
				</Stack>
			</>
		);
	};

	return (
		<CustomDialog
			isOpen={isOrderCheckDialogOpen}
			setIsOpen={setIsOrderCheckDialogOpen}
			dialogInfo={{
				title: i18n.t("orderCheck.Title"),
			}}
			customActions={
				isLoadingDealers || isDealerListEmpty ? (
					<></>
				) : (
					<OrderCheckActions
						isSubmitDisabled={
							checkedItemsQuantity !== orderModel.itemQuantity ||
							currentDealer === null ||
							Number(packagesQuantity) < 1 ||
							isDealerListEmpty
						}
						isLoading={isLoadingSubmit}
						handleDataReset={handleDataReset}
						onCancelCallback={onCancelCallback}
						onSubmitCallback={onSubmit}
					/>
				)
			}
			dialogStyles={{
				titleVariant: "title",
				maxWidth: "lg",
				actionStyles: { justifyContent: "space-between" },
			}}
		>
			<OrderCheckHeader
				orderModel={orderModel}
				currentDealer={currentDealer}
				setCurrentDealer={setCurrentDealer}
				handleAutocompleteChange={handleAutocompleteChange}
				emptyDealersCallback={emptyDealersCallback}
				triggerLoadingDealers={triggerLoadingDealers}
			/>
			<Divider sx={{ marginY: 3 }} />
			{renderContent()}
			<AttentionDialog
				isAttentionDialogOpen={isAttentionDialogOpen}
				setIsAttentionDialogOpen={setIsAttentionDialogOpen}
				closeCallback={() => setNotFoundEan("")}
			>
				<CustomTypography variant="default" sx={{ textAlign: "center" }}>
					{insertHtml(
						i18n.t("orderCheck.EanError").replace("%X", notFoundEan),
						notFoundEan,
						<strong>{notFoundEan}</strong>
					)}
				</CustomTypography>
			</AttentionDialog>
		</CustomDialog>
	);
}
