import {
	getEstimateDate,
	showOrderCode,
} from "../../../infrastructure/utils/OrdersUtils";
import {
	getSafeStringValue,
	normalizeDigitsOnly,
} from "../../../infrastructure/utils/StringUtils";
import { LegacyActionLogDto } from "../../dtos/legacyCommerceManagement/LegacyActionLogDto";
import { LegacyCustomDataDto } from "../../dtos/legacyCommerceManagement/LegacyCustomDataDto";
import { LegacyItemDto } from "../../dtos/legacyCommerceManagement/LegacyItemDto";
import { LegacyOrderDto } from "../../dtos/legacyCommerceManagement/LegacyOrderDto";
import { CustomDataDto } from "../../dtos/smOrders/CustomDataDto";
import { ItemDto } from "../../dtos/smOrders/items/ItemDto";
import { OrderDto } from "../../dtos/smOrders/OrderDto";
import { ActionLogModel } from "../../models/commerce/ActionLogModel";
import { CustomDataModel } from "../../models/commerce/CustomDataModel";
import { ItemModel } from "../../models/commerce/ItemModel";
import { OrderModel } from "../../models/commerce/OrderModel";

const getProductName = (
	skuName: string | null,
	productName: string | null
): string => {
	if (skuName && productName) {
		return skuName.toLowerCase().includes(productName.toLowerCase())
			? skuName
			: `${productName} - ${skuName}`;
	}
	return skuName || productName || "";
};

const legacyToOrderModel = (order: LegacyOrderDto): OrderModel => {
	const orderCode: string = showOrderCode(
		order.friendlyCode,
		order?.externalId,
		order?.sellerOrderId
	);
	const customData: CustomDataModel[] = order.customData.map(
		(data: LegacyCustomDataDto): CustomDataModel => ({
			key: data.key,
			value: data.value,
		})
	);

	const actionLogs: ActionLogModel[] = order.actionLogs.map(
		(actionLog: LegacyActionLogDto): ActionLogModel => ({
			title: actionLog.title,
			displayMessage: actionLog.displayMessage,
		})
	);

	const itemQuantity: number = order.items.reduce(
		(acc, currentValue) => acc + currentValue.quantity,
		0
	);

	const items: ItemModel[] = order.items.map((item: LegacyItemDto) => {
		const name: string = getProductName(item.sku.name, item.sku.product.name);

		if (item.sku.barcode === "null") item.sku.barcode = null;

		return {
			id: item.id,
			ean: item.sku.barcode,
			name: name,
			productCode: item.sku.product.code,
			quantity: item.quantity,
			skuCode: item.sku.code,
		};
	});

	const genericOrder: OrderModel = {
		actionLogs: actionLogs,
		id: order.id,
		orderCode: orderCode,
		isDelivery: order.isDelivery,
		estimateDate: getEstimateDate(order.freight?.estimateDate),
		customData: customData,
		status: order.status,
		itemQuantity: itemQuantity,
		totalAmount: order.totalAmount,
		items: items,
		buyer: {
			fullName: order.buyer.fullName,
			phoneNumber: order.buyer.phoneNumber,
		},
		seller: {
			fullName: order.seller.fullName,
			address: {
				city: getSafeStringValue(order.seller.address?.city),
				complement: getSafeStringValue(order.seller.address?.complement),
				neighborhood: getSafeStringValue(order.seller.address?.neighborhood),
				number: getSafeStringValue(order.seller.address?.number),
				state: getSafeStringValue(order.seller.address?.state),
				street: getSafeStringValue(order.seller.address?.street),
				zipCode: getSafeStringValue(order.seller.address?.cep),
			},
		},
		shippingData: {
			deliveryAddress: order.isDelivery
				? {
						city: order.deliveryAddress.city,
						neighborhood: order.deliveryAddress.neighborhood,
						number: order.deliveryAddress.number,
						state: order.deliveryAddress.state,
						street: order.deliveryAddress.street,
						zipCode: normalizeDigitsOnly(order.deliveryAddress.cep),
						complement: order.deliveryAddress.complement,
				  }
				: null,
		},
		pickingInfo: null,
	};

	return genericOrder;
};

const smToOrderModel = (order: OrderDto): OrderModel => {
	const orderCode: string = showOrderCode(
		order.FriendlyCode,
		order?.ExternalId,
		order?.SellerOrderId
	);

	const customData: CustomDataModel[] = order.CustomData.map(
		(data: CustomDataDto): CustomDataModel => ({
			key: data.Key,
			value: data.Value,
		})
	);

	const itemQuantity: number = order.Items.reduce(
		(acc, currentValue) => acc + currentValue.Quantity,
		0
	);

	const items: ItemModel[] = order.Items.map((item: ItemDto) => {
		return {
			id: item.Sku.Code,
			ean: item.Sku.BarCode === "null" ? null : item.Sku.BarCode,
			name: item.Sku.Name,
			productCode: item.Sku.ProductCode ?? "",
			quantity: item.Quantity,
			skuCode: item.Sku.Code,
		};
	});

	const genericOrder: OrderModel = {
		actionLogs: null,
		id: order.id,
		orderCode: orderCode,
		isDelivery: order.IsDelivery,
		estimateDate: getEstimateDate(
			order.ShippingData.Shipment.EstimatedDeliveryDate
		),
		customData: customData,
		status: order.Status,
		itemQuantity: itemQuantity,
		totalAmount: order.Totals.TotalAmount,
		items: items,
		buyer: {
			fullName: order.Buyer.FullName,
			phoneNumber: order.Buyer.PhoneNumber,
		},
		seller: {
			fullName: getSafeStringValue(order.Seller?.FullName),
			address: {
				city: getSafeStringValue(order.Seller?.Address.City),
				complement: getSafeStringValue(order.Seller?.Address.Complement),
				neighborhood: getSafeStringValue(order.Seller?.Address.Neighborhood),
				number: getSafeStringValue(order.Seller?.Address.Number),
				state: getSafeStringValue(order.Seller?.Address.State),
				street: getSafeStringValue(order.Seller?.Address.Street),
				zipCode: getSafeStringValue(order.Seller?.Address.ZipCode),
			},
		},
		shippingData: {
			deliveryAddress: order.IsDelivery
				? {
						city: order.ShippingData.DeliveryAddress.City,
						complement: getSafeStringValue(
							order.ShippingData.DeliveryAddress.Complement
						),
						neighborhood: order.ShippingData.DeliveryAddress.Neighborhood,
						number: order.ShippingData.DeliveryAddress.Number,
						state: order.ShippingData.DeliveryAddress.State,
						street: order.ShippingData.DeliveryAddress.Street,
						zipCode: order.ShippingData.DeliveryAddress.ZipCode,
				  }
				: null,
		},
		pickingInfo: order.PickingInfo
			? {
					packagesQuantity: order.PickingInfo.PackagesQuantity,
					picker: {
						code: order.PickingInfo.Picker.Code,
						document: order.PickingInfo.Picker.Document,
						name: order.PickingInfo.Picker.Name,
					},
			  }
			: null,
	};

	return genericOrder;
};

export const OrderMapper = { legacyToOrderModel, smToOrderModel };
